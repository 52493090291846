var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":24,"lg":12,"xl":6}},[_c('WidgetCounter',{attrs:{"title":stat.title,"value":stat.value,"prefix":stat.prefix,"suffix":stat.suffix,"icon":stat.icon,"status":stat.status}})],1)}),1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":16}}),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":8}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',_vm._b({attrs:{"label":"Date de synchronisation"}},'a-form-item',_vm.formItemLayout,false),[_c('div',{staticClass:"d-flex"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'Nombre de mois',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Nombre de mois est vide!',
                    } ],
                } ]),expression:"[\n                'Nombre de mois',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Nombre de mois est vide!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"border-radius":"5px 0 0 5px"},attrs:{"type":"date","placeholder":"Nombre du jour"}}),_c('a-button',{staticStyle:{"border-radius":"0 5px 5px 0"},attrs:{"type":"primary"}},[_vm._v("Valider")])],1)])],1)],1),_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":12,"lg":12,"xl":24}},[_c('a-card',{staticClass:"card card-body border-0"},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('router-link',{staticClass:"mx-2",attrs:{"to":{
                name: 'Synchronisation_detail',
                params: { id: record.key },
              }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1)]}}],null,true)})],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }